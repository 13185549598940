import config from "../config";
import {debounce} from "../tools/misc";
import {hit, hits} from "../tools/hits";
import {logEvent, userEvents} from "../tools/log";
import {authorize, getAuthorizedAccounts} from "../helpers/auth";
import {appendParamsToUrl} from "../tools/url";
import calcTokens from "../tools/calcTokens";
import {gaEvents, gaSendEvent} from "../tools/ga";

const ethAmountQueryKey = "eth_amount";

let web3;

let $purchaseForm;
let $purchaseFormAmountField;
let $purchaseFormMessage;
let $purchaseFormStatusMessage;
let $tokensAmount;

let $connectWalletButton;

$(document).ready(() => {
  $purchaseForm = $(".js-purchase-form");
  $purchaseFormAmountField = $purchaseForm.find("input[name='amount']");
  $purchaseFormMessage = $purchaseForm.find(".js-result-message");
  $purchaseFormStatusMessage = $purchaseForm.find(".js-result-status");
  $tokensAmount = $purchaseForm.find(".how__pay-get b");

  if (config.query[ethAmountQueryKey]) {
    $purchaseFormAmountField.val(config.query[ethAmountQueryKey]);
  }

  $purchaseFormAmountField.on("input", function (e) {
    const ethAmount = Math.max(0, parseFloat($purchaseFormAmountField.val()));

    if (!ethAmount || ethAmount <= 0) {
      $tokensAmount.text("≈N");
      return;
    }

    if (!window.toonAppData.ethPrice || !window.toonAppData.totalSupply) {
      return;
    }

    debounce("calc_tokens", 400, function() {
      const ethAmount = Math.max(0, parseFloat($purchaseFormAmountField.val()));
      const usdAmount = (ethAmount * window.toonAppData.ethPrice) / Math.pow(10, 8);

      calcTokens.calc(usdAmount, window.toonAppData.totalSupply)
        .then((res) => {
          const amount = ethAmount >= 0.1
            ? (res.out.tokensAmount / 1e18).toFixed(2)
            : (res.out.tokensAmount / 1e18);

          $tokensAmount.text(`≈${amount}`);
        }).catch(function(err) {
          $tokensAmount.text("≈N");
        });
    });
  });

  // purchase form
  $purchaseForm.on("submit", function(e) {
    e.preventDefault();

    const amount = Math.max(0, parseFloat($purchaseFormAmountField.val()));
    if (amount <= 0) {
      return;
    }

    gaSendEvent(gaEvents.purchaseSubmit, {eth_amount: amount});
    logEvent(userEvents.purchaseSubmit, {amount: amount});

    $(".how__pay-loading").removeClass("hidden");
    $(".how__pay-error").addClass("hidden").text("");
    $purchaseFormMessage.addClass("hidden");

    let selectedAccount;

    debounce("purchase", 400, function() {
      const amount = Math.max(0, parseFloat($purchaseFormAmountField.val()));

      authorize({
        force: false,
        metaMaskDeeplinkUrl: appendParamsToUrl(window.location.href, {
          [ethAmountQueryKey]: amount,
        }),
      }).then((res) => {
        web3 = res;
        return getAuthorizedAccounts();
      }).then((accounts) => {
        selectedAccount = accounts[0];

        logEvent(userEvents.purchaseRequest, {
          amount: amount,
          address: selectedAccount,
        });
        hit(hits.purchaseRequest);

        return web3.eth.sendTransaction({
          from: selectedAccount,
          to: window.toonAppData.address,
          data: "0x64edfbf0e2c706ba4a09595315c45355a341a576cc17f3a19f43ac1c02f814ee",
          value: web3.utils.toWei(amount.toString(), "ether"),
        }).on("transactionHash", function(txHash) {
          gaSendEvent(gaEvents.purchaseTx);
          logEvent(userEvents.purchaseTx);

          $(".how__pay-loading").addClass("hidden");

          if (txHash !== undefined) {
            $purchaseFormAmountField.val("");
            scheduleTxCheckStatus(txHash);
          }
        });
      }).then(function(receipt) {
        console.log("receipt2", receipt);
      }).catch(function(err) {
        console.error(err);

        gaSendEvent(gaEvents.purchaseError, {error_code: err.code});
        logEvent(userEvents.purchaseError, {amount: amount, address: selectedAccount});
        hit(hits.purchaseError);

        $(".how__pay-loading").addClass("hidden");

        if (err.code === 4001) {
          return;
        }

        $(".how__pay-error").removeClass("hidden").text(err.message);

        $([document.documentElement, document.body]).animate({
          scrollTop: $(".how__pay-error").offset().top - $(window).height()/2
        }, 2000);

      });
    });
  });

  $connectWalletButton = $(".js-connect-wallet");

  $connectWalletButton.on("click", () => {
    gaSendEvent(gaEvents.connectWalletButton);
    logEvent(userEvents.connectWalletButton);

    debounce("connect", 400, function() {
      authorize({force: true})
        .then((res) => {
          web3 = res;
        })
        .catch(function(err) {
          console.error(err);

          if (err.code === 4001) {
            return;
          }
        });
    });
  });
});

let checkTxStatusTimer = null;
function scheduleTxCheckStatus(txHash, delay = 1000) {
  clearTimeout(checkTxStatusTimer);
  checkTxStatusTimer = setTimeout(() => txCheckStatus(txHash), delay);
}

function txCheckStatus(txHash) {
  $purchaseFormStatusMessage.removeClass("hidden");
  $purchaseFormStatusMessage.find("span").text("PENDING");
  $purchaseFormStatusMessage.find("a").attr("href", "https://etherscan.io/tx/" + txHash);

  web3.eth.getTransactionReceipt(txHash).then((data) => {
    if (data === null) {
      scheduleTxCheckStatus(txHash);
      return;
    }

    if (data.blockNumber > 0) {
      $purchaseFormStatusMessage.find("span").text("MINED");
    }

    if (data.status === true && data.blockNumber > 0) {
      gaSendEvent(gaEvents.purchaseSuccess);
      logEvent(userEvents.purchaseSuccess);
      hit(hits.purchaseSuccess);

      $purchaseFormStatusMessage.addClass("hidden");
      $purchaseFormStatusMessage.find("span").text("SUCCESS");

      $purchaseFormMessage.removeClass("hidden");
      $purchaseFormMessage.find("span").text("Thank you for your purchase!");
      $purchaseFormMessage.find("a").attr("href", "https://etherscan.io/tx/" + txHash);
    } else {
      scheduleTxCheckStatus(txHash);
    }
  }).catch((err) => {
    console.error("getTransactionReceipt error", err);

    $purchaseFormStatusMessage
      .find("span")
      .html(`ERROR <a href='https://etherscan.io/tx/${txHash}'>(details)</a>`);

    scheduleTxCheckStatus(txHash);
  });
}